import React from 'react'
import { SocialIcon } from "react-social-icons";
import * as Scroll from "react-scroll"
import { graphql } from 'gatsby'
import PostPreview from '../components/blog/PostPreview';
import WorkPreview from '../components/work/WorkPreview';
import Layout from '../components/_shared/Layout';

const IndexPage = ({ data }) => {
  const links = [
    "https://www.linkedin.com/in/jaksabasic",
    "https://github.com/jaksa-b",
    "https://twitter.com/jaksabasic",
    // "https://www.instagram.com/jaksa_b",
    // "https://www.youtube.com/channel/UCahAJW5yOaodpdHFrc7ojYw",
    "mailto:jaksabasic1@gmail.com"
  ]

  const posts = data.allMarkdownRemark.edges;

  const works = [/* {
    id: 'booster',
    title: 'Get gas delivered while you work',
    type: 'Responsive website',
    description: 'Html5, Css3, Sass, React, Redux, Next.js',
    backgroundImg: '/work/booster/bg.jpeg',
    websiteImg: '/work/booster/web.jpg',
    websiteImg2: '/work/booster/dashboard.jpg',
    siteUrl: 'https://www.trybooster.com',
    myProject: false,
    sourceUrl: '',
    options: {
      container: {
        flexDirection: 'row'
      },
      overlay: {
        background: 'rgba(115, 7, 178, 0.7)'
      }
    }
  } */,{
    id: 'cimer',
    title: 'Find roommate with who you can live',
    type: 'Responsive website',
    description: 'Html5, Css3, Sass, React, Meteor.js',
    backgroundImg: '/work/cimer/bg.jpg',
    websiteImg: '/work/cimer/web.jpg',
    websiteImg2: '/work/cimer/rooms.jpeg',
    siteUrl: 'https://cimer.hr',
    myProject: true,
    sourceUrl: '',
    options: {
      container: {
        flexDirection: 'row-reverse'
      },
      overlay: {
        background: 'rgba(0, 0, 0, 0.7)'
      }
    }
  },{
    id: 'hoteljarun',
    title: 'Hotel Jarun',
    type: 'Responsive website',
    description: 'Html5, Css3, Sass, Semantic UI, React, Meteor.js, Reservation System',
    backgroundImg: '/work/hotel-jarun/bg.jpg',
    websiteImg: '/work/hotel-jarun/web.jpg',
    websiteImg2: '/work/cimer/rooms.jpeg',
    siteUrl: 'https://hotel-jarun.com/',
    myProject: false,
    sourceUrl: '',
    options: {
      container: {
        flexDirection: 'row'
      },
      overlay: {
        background: 'rgba(234, 12, 42, 0.7)'
      }
    }
  }];

  return (
    <Layout>
      <header className="main-header">
        <div className="main-header__overlay">
          <div className="text-container">
            <div className="main-header__content">
              <img src="/images/me.jpeg" alt="" className="avatar" />
              <h2>Jakša Bašić</h2>
              <h5>Javascript Developer, maker of <a href="https://cimer.hr">cimer.hr</a>. Love to travel, make short movies, play drums.</h5>
              <div className="social-icons">
                {links.map((link, i) => (
                  <SocialIcon
                    key={i}
                    className="social-icon"
                    color="#999"
                    url={link}
                  />
                ))}
              </div>
              <Scroll.Link
                className="scroll-down icon-arrow-left"
                to="content"
                spy
                smooth
                duration={500}
              />
            </div>
          </div>
        </div>
      </header>
      <main id="content">
        {works.map((work, i) => <WorkPreview key={i} work={work} next={work[i+1]} />)}
        <div className="blog-posts">
          {posts.map((edge, i) => <PostPreview key={i} node={edge.node} />)}
        </div>
      </main>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
      description
    }
  }
  allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD MMMM, YYYY")
          title
          cover {
            publicURL
          } 
        }
      }
    }
  }
}
`