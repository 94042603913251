import React from 'react'
import { Link } from 'gatsby'
import './PostPreview.scss'

const PostPreview = ({ node }) => {
    return (
        <Link to={node.fields.slug}>
            <div className="post-preview" style={{
                background: `url(${node.frontmatter.cover.publicURL}) no-repeat center center`,
                backgroundSize: 'cover'
            }}>
                <div className="post-preview__overlay">
                    <div className="text-container">
                        <h2>{node.frontmatter.title}</h2>
                        <small>{node.frontmatter.date}</small>
                        <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default PostPreview