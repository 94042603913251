import React from 'react'
import styled from 'styled-components';

const Browser = styled.div``
const Header = styled.div`
  display: flex;
  height: 20px;
  background: #1d1e20;
  padding: 3px 10px;
  @media all and (min-width: 500px) {
    height: 35px;
    padding: 3px 20px;
  }
`
const Controles = styled.div`
  display: inline-flex;
  padding-top: 5px;
  @media all and (min-width: 500px) {
    padding-top: 10px;
  }
`
const ControlButton = styled.div`
  width: 5px;
  height: 5px;
  background: white;
  border-radius: 50%;
  margin-right: 5px;
  @media all and (min-width: 500px) {
    width: 10px;
    height: 10px;
  }
`
const Menu = styled.div``
const MenuBar = styled.div`
  width: 15px;
  height: 1px;
  background: white;
  margin: 3px 0;
  border-radius: 20px;
  @media all and (min-width: 500px) {
    width: 20px;
    height: 3px;
    margin: 5px 0;
  }
`
const Title = styled.div`
  flex: 2;
  color: white;
  font-size: 12px;
  text-align: center;
  padding-top: 2px;
`

const WebBrowser = ({ children, title }) => (
  <Browser>
    <Header>
      <Controles>
        <ControlButton />
        <ControlButton />
        <ControlButton />
      </Controles>
      <Title>
        {title}
      </Title>
      <Menu>
        <MenuBar />
        <MenuBar />
        <MenuBar />
      </Menu>
    </Header>
    { children }
  </Browser>
)

export default WebBrowser;
