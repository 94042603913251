import React from 'react'
import styled from 'styled-components';
import { WebBrowser } from '../elements';

const Wrapper = styled.section.attrs(p => ({ id: p.id }))`
  background: url(${p => p.background}) no-repeat center center;
  background-size: cover;
  position: relative;
  height: 100vh;
  overflow: hidden;
`
const Contaner = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px 0;
  height: 100%;
  width: 100%;
  text-align: center;
  /* Medium screens */
  @media all and (min-width: 960px) {
    width: 90%;
    flex-wrap: wrap;
    text-align: left;
    flex-direction: ${p => p.options.flexDirection};
  }
`

const Header = styled.div`
  color: white;
  flex: 1;
  max-width: 400px;
  margin: 20px auto;
  text-align: center;
  @media all and (min-width: 960px) {
    text-align: ${p => p.options.flexDirection === 'row-reverse' ? 'left' : 'right'};
    margin-top: 15%;
  }
`

const P = styled.p`
  width: 250px;
  margin: 20px auto;
  @media all and (min-width: 960px) {
    margin: ${p => p.options.flexDirection === 'row-reverse' ? '20px 0px' : '20px 0px 20px 37%'};
  }
`

const Content = styled.div`
  margin: 20px;
  flex: 1;
`
const H1 = styled.h1`
  color: white;
`
const Img = styled.img`
  width: 100%;
`
/* const SecondImgWrapper = styled.div`
  position: absolute;
  width: 800px;
  bottom: -160px;
  left: 220px;
` */

const Button = styled.a`
  text-decoration: none;
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px 20px;
  border: 1px solid white;
`

const Ribbon = styled.div`
  width: 0;
  height: 0;
  right: 0;
  border-style: solid;
  position: absolute;
  border-width: 0 100px 100px 0;
  border-color: transparent #000 transparent transparent;
`
const RibbonBody = styled.div`
  width: 40px;
  top: 10px;
  left: 45px;
  position: absolute;
  transform: rotate(45deg);
`

const Overlay = styled.div`
  background: ${p => p.options.background};
  height: 100vh;
`

const WorkPreview = ({ work }) => (
  <Wrapper background={work.backgroundImg} id={work.id}>
    <Overlay options={work.options.overlay}>
      {work.sourceUrl !== '' &&
        <Ribbon>
          <RibbonBody>
            <img src="/work/githubLogo.png" alt="" />
          </RibbonBody>
        </Ribbon>
      }
      <Contaner options={work.options.container}>
        <Header options={work.options.container}>
          <H1>{work.title}</H1>
          <span>{work.type}</span>
          <P options={work.options.container}>{work.description}</P>
          <Button href={work.siteUrl} target="_blank">View Project</Button>
        </Header>
        <Content>
          <WebBrowser>
            <Img src={work.websiteImg} alt="" />
          </WebBrowser>
          {/* <SecondImgWrapper>
              <WebBrowser title="Dashboard">
                <Img src={work.websiteImg2} alt="" />
              </WebBrowser>
            </SecondImgWrapper> */}
        </Content>
      </Contaner>
    </Overlay>
  </Wrapper>
)

export default WorkPreview
